import React from "react"

import { graphql } from "gatsby"

import PropTypes from "prop-types"

import ProductList from "../components/products/ProductList"
import SEO from "../components/seo"

const SimilarProductsTemplate = ({ data }) => {
  const { product } = data.saleor
  const [similarProdcts, setSimilarProducts] = React.useState({})

  React.useEffect(() => {
    if (product) {
      const similar = product.productType.products.edges.filter(
        (prod) => prod.node.id !== product.id
      )
      setSimilarProducts({ edges: similar })
    }
  }, [product])

  return (
    <>
      <SEO title={product.seoTitle} path={product.seoDescription} />
      <ProductList
        title={`Products similar to ${product.name}`}
        products={similarProdcts}
      />
    </>
  )
}

export const productQuery = graphql`
  query SimilarProductsBySlug($slug: String!) {
    saleor {
      product(slug: $slug) {
        id
        slug
        name
        seoTitle
        seoDescription
        productType {
          name
          slug
          products(first: 100) {
            edges {
              node {
                id
                slug
                name
                visibleInListings
                isAvailable
                isAvailableForPurchase
                variants {
                  name
                  id
                  pricing {
                    price {
                      gross {
                        amount
                        currency
                      }
                    }
                  }
                }
                thumbnail {
                  url
                  alt
                }
                pricing {
                  onSale
                  priceRange {
                    start {
                      net {
                        amount
                      }
                    }
                    stop {
                      net {
                        amount
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

SimilarProductsTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SimilarProductsTemplate
